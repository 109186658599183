import type { FC } from 'react'
import { Link } from '@remix-run/react'
import Logo from '~/assets/images/svg/logo_2.svg'

type HeaderProps = {
  className?: string
}

export const LogoHeader: FC<HeaderProps> = ({
  className = '',
}) => {
  return (
    <header className="flex flex-col" >
      <div className={`flex flex-col shadow bg-white md:flex-row w-full items-center md:justify-between p-2 h-auto md:h-24 ${className}`}>
        <Link to="/" className="h-full w-5/12 md:basis-1/3 flex justify-center align-center" >
          <img className="m-auto h-7 md:h-auto" src={Logo} />
        </Link>
      </div>
    </header>
  )
}
