import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useMatches,
  useLoaderData,
  useLocation,
  useRouteError,
  isRouteErrorResponse,
} from "@remix-run/react";
import { LoaderFunction, json } from "@remix-run/node";
import type { LinksFunction } from "@remix-run/node";
import { createContext, useEffect, useState } from "react";
import { ErrorLayout } from "~/components/templates/user/error";
import { LogoHeader } from "~/components/compounds/user/logo-header";
import { Footer } from "~/components/footer";
import styles from "~/styles/app.css?url";
import {
  getGTMContainerID,
  getAppBaseURL,
  getBrowserEnvironment,
} from "~/utils/application.server";
import type { BrowserEnvironment } from "~/utils/application";
import { MakerModelJSON } from "~/models";
import { MakerUseCase } from "~/usecases/maker.server";
import * as Sentry from "@sentry/remix";

export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

export type RootContextType = {
  isScrollable: boolean;
  setIsScrollable: (isMenuOpened: boolean) => void;
  makers: MakerModelJSON[];
};

type LoaderData = {
  gtmContainerID: string;
  appBaseURL: string; // to use meta tag
  makers: MakerModelJSON[];
  browserEnvironment: BrowserEnvironment;
};

export const loader: LoaderFunction = async () => {
  const makerUseCase = new MakerUseCase();
  const makerModels = await makerUseCase.findAll();
  return json<LoaderData>({
    gtmContainerID: getGTMContainerID(),
    appBaseURL: getAppBaseURL(),
    makers: makerModels,
    browserEnvironment: getBrowserEnvironment(),
  });
};

export const RootContext = createContext<RootContextType>({
  setIsScrollable: (isScrollable: boolean) => { },
  isScrollable: false,
  makers: [],
});

declare global {
  interface Window {
    dataLayer?: object[];
  }
}

const initSentry = (browserEnvironment: BrowserEnvironment) => {
  Sentry.init({
    dsn: browserEnvironment.SENTRY_DSN,
    tracesSampleRate: 1,
    environment: browserEnvironment.APP_ENV,

    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
  });
}

const initGTM = () => {
  window.dataLayer = window.dataLayer || [];
  if (!window.dataLayer.find((el) => "gtm.start" in el)) {
    window.dataLayer.push({
      event: "gtm.js",
      "gtm.start": new Date().getTime(),
    });
  }
}

function App() {
  const { gtmContainerID, makers, browserEnvironment } =
    useLoaderData<LoaderData>();
  const [isScrollable, setIsScrollable] = useState<boolean>(true);

  useEffect(() => {
    if (browserEnvironment.APP_ENV === 'development') return
    initGTM()
    initSentry(browserEnvironment)
  }, []);

  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <script
          async={true}
          src={`https://www.googletagmanager.com/gtm.js?id=${gtmContainerID}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(browserEnvironment)}`,
          }}
        />
        <Meta />
        <Links />
      </head>
      <body className={`${isScrollable ? "" : "overflow-hidden"}`}>
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmContainerID}" height="0" width="0" style="display: none, visibility: hidden"></iframe>`,
          }}
        />
        <RootContext.Provider
          value={{
            setIsScrollable: setIsScrollable,
            isScrollable: isScrollable,
            makers: makers,
          }}
        >
          <Outlet />
          <Scripts />
        </RootContext.Provider>
      </body>
    </html>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError() as Error;

  captureRemixErrorBoundaryError(error);

  return (
    <html>
      <head>
        <title>Server Error</title>
        <meta name="robots" content="noindex" />
        <Meta />
        <Links />
      </head>
      <body>
        <ErrorLayout
          header={<LogoHeader />}
          footer={<Footer />}
          children={
            isRouteErrorResponse(error) && error.status == 404 ? (
              <div className="p-4">
                <h2 className="text-lg text-center">
                  お探しのページが見つかりません
                </h2>
              </div>
            ) : (
              <div className="p-4">
                <h2 className="text-lg text-center">エラーが発生しました</h2>
                <p className="my-4">
                  お手数ですがしばらくしてからアクセスしてください。
                </p>
              </div>
            )
          }
        />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
